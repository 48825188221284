<template>
	<v-container fluid>
		<h3 class="lead">{{ $t('registeredSuccess') }}</h3>

		<p v-if="success">{{ email }}, {{ $t('verifySuccess') }}</p>

		<p v-if="!success">
			{{ $t('verifyError') }}
		</p>

		<v-btn color="primary" @click="goToLogin">{{ $t('login') }}</v-btn>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			url: process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1',
			success: false,
			email: '',
			isLoading: false,
		};
	},
	methods: {
		goToLogin() {
			this.$router.push('/login');
		},
	},
	async mounted() {
		try {
			this.isLoading = true;

			const res = await this.$http.put(
				`${this.url}/auth/verifyemail/${this.$route.params.verificationtoken}`,
			);

			this.email = res.data.data.email;

			this.success = true;

			this.isLoading = false;
		} catch (error) {
			console.error(error);

			this.isLoading = false;
		}
	},
};
</script>

<style lang="scss" scoped></style>
